export default function ServicesIntegrativeWellnessAndLifestyleMedicine() {
    return (
      <>
        <div className="row content">
          <div id="bannerServicesIntegrativeWellnessAndLifestyle" className="page-featured-image"></div>
          <h1>Integrative Wellness and Lifestyle Medicine</h1>
          <p>Integrative Wellness</p>
          <p>Personalized to an individual's health goals, integrative wellness combines the consideration of one's well-being with conventional medical approaches. As a more holistic approach to healthcare, integrative wellness considers all components of an individual’s life; from physical, mental, and emotional health to interpersonal relationships.</p>

          <h2>Background</h2>
          <p>The field of integrative medicine began in the 1960s, yet it took until 1995 to be recognized as a direction for modern medicine. The foundation of integrative wellness is integrative medicine. Per the American Board of Physician Specialties, integrative medicine values the relationship between practitioner and patient, "...focuses on the whole person, is informed by evidence, and makes use of all appropriate therapeutic approaches, healthcare professionals, and disciplines to achieve optimal health and healing."</p>
          <p>Integrative wellness is commonly applied to the treatment of individuals who have been diagnosed with chronic diseases, such as cardiovascular disease, pre-diabetes, diabetes, obesity, anxiety, depression, and stress.</p>
          <p>This method of treatment includes a whole-person approach, designed to target the psychological, physical, and nutritional elements of an individual’s life.</p>

          <h2>How does Integrative Wellness Compare to Lifestyle Medicine?</h2>
          <p>Surprisingly enough, there's little to no difference between integrative wellness and lifestyle medicine. Both methods of healthcare center around the well-being of a whole person, rather than a singular component in their life. Both include the research, diagnosis, and treatment of conventional healthcare with holistic approaches and have the goal to prevent, treat, and reverse chronic diseases.</p>

          <hr />
  		</div>
      </>
    );
  }
  