export default function SpecialtyChildrenAndTeensHealth() {
    return (
      <>
      <div className="row content">
        <div id="bannerSpecialtyChildrenTeens" className="page-featured-image"></div>
        <h1>Children and Teens Health</h1>
        <p>Under construction.</p>
        <hr />
      </div>
      </>
    );
  }
  