export default function ServicesExerciseCounseling() {
    return (
      <>
      <div className="row content">
        <div id="bannerServicesExerciseCounseling" className="page-featured-image"></div>
        <h4>Exercise Counseling</h4>
        <p>Under Construction...</p>
		  </div>
      </>
    );
  }
  