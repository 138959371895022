export default function SpecialtyChronicConditions() {
    return (
      <>
      <div className="row content">
        <div id="bannerSpecialtyChronicConditions" className="page-featured-image"></div>
        <h1>Chronic Conditions</h1>
        <p>Characteristics of a chronic illness</p>
        <p>Chronic illnesses are mostly characterized by complex causes, multiple risk factors, long latency periods (time between onset of the illness and feeling its effects), functional impairment or disability.</p>
        <p>Most chronic illnesses do not fix themselves and are generally not cured completely. Some can be immediately life-threatening, such as heart disease and stroke. Others linger over time and need intensive management, such as diabetes. Most chronic illnesses persist throughout a person’s life, but are not always the cause of death, such as arthritis. </p>
        <p>According to current estimates, more than 54 million Americans live with some type of long-lasting condition or disability [1]. </p>
        <p>Our aim is to support patients and family members with chronic and metabolic conditions, and to design plans specifically to individual needs. We provide a range of services, including:</p>

        <p>Education on health and life skills</p>
        <p>Emotional and social mentoring</p>
        <p>Fitness consultations</p>
        <p>Integrative health and nutrition consultations</p>

        <p>References</p>
        <p>1. Giannini M. Office on Disability, U.S. Department of Health and Human Services. Disability Health J. 2008;1(1):5–6. [PubMed] [Google Scholar]</p>
        <hr />
      </div>
      </>
    );
  }
  