export default function ServicesPage() {
    return (
      <>
        <div className="content">
        <h1>Servces</h1>
        <p>-</p>
      </div>
      </>
    );
  }
  